var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"data-html2canvas-ignore":"true"}},[_c('v-app-bar',{attrs:{"prominent":"","color":_vm.scrolled ? 'white' : 'transparent',"shrink-on-scroll":"","elevate-on-scroll":"","fixed":""}},[_c('v-container',{staticClass:"py-0 fill-height"},[(_vm.$vuetify.breakpoint.xs && _vm.$route.name !== 'FAQs')?_c('v-app-bar-nav-icon',{staticClass:"mb-4",on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}}):_vm._e(),_c('v-img',{staticClass:"mx-auto mb-4",attrs:{"src":require("@/assets/logo.svg"),"alt":_vm.$t('RSA Logo'),"aspect-ratio":"1","title":_vm.$t('RSA Logo'),"max-height":"50","max-width":"175","contain":""}}),(!_vm.$vuetify.breakpoint.xs && _vm.$route.name === 'Home')?[_c('v-btn',{staticClass:"ml-2 mb-3",attrs:{"text":""},on:{"click":function($event){return _vm.$vuetify.goTo(0, {
              duration: 300,
              offset: 0,
              easing: 'easeInOutCubic',
            })}}},[_vm._v(" "+_vm._s(_vm.$t("Sign Up"))+" ")]),_c('v-btn',{staticClass:"mb-3",attrs:{"text":""},on:{"click":function($event){return _vm.$vuetify.goTo(_vm.processFlow, {
              duration: 300,
              offset: 0,
              easing: 'easeInOutCubic',
            })}}},[_vm._v(" "+_vm._s(_vm.$t("Process Flow"))+" ")])]:_vm._e(),(!_vm.$vuetify.breakpoint.xs && _vm.$route.name !== 'FAQs')?_c('v-btn',{staticClass:"mb-3",attrs:{"to":{ name: 'FAQs' },"target":"_blank","text":""}},[_vm._v(" "+_vm._s(_vm.$t("FAQs"))+" ")]):_vm._e(),_c('v-spacer'),(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',{staticClass:"mr-3 mb-3",staticStyle:{"font-size":"0.75em"}},[_vm._v(" "+_vm._s(_vm.$t("Preferred language"))+" ")]):_vm._e(),_c('v-select',{staticClass:"shrink mt-2",attrs:{"items":_vm.langs},on:{"change":function($event){return _vm.changeLocale()}},model:{value:(_vm.$root.$i18n.locale),callback:function ($$v) {_vm.$set(_vm.$root.$i18n, "locale", $$v)},expression:"$root.$i18n.locale"}}),(_vm.$vuetify.breakpoint.mdAndUp)?_c('v-btn',{staticClass:"mb-3 ml-3",attrs:{"target":"_blank","href":"https://clickdna.rsa.global/","color":"error"}},[_vm._v(" "+_vm._s(_vm.$t("Login"))+" ")]):_vm._e()],2)],1),(_vm.$vuetify.breakpoint.xs && _vm.$route.name !== 'FAQs')?_c('v-navigation-drawer',{attrs:{"absolute":"","left":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item-group',{attrs:{"active-class":"deep-purple--text text--accent-4"}},[(_vm.$route.name === 'Home')?_c('v-list-item',{on:{"click":function($event){return _vm.$vuetify.goTo(0, {
              duration: 300,
              offset: 0,
              easing: 'easeInOutCubic',
            })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Sign Up")))])],1):_vm._e(),(_vm.$route.name === 'Home')?_c('v-list-item',{on:{"click":function($event){return _vm.$vuetify.goTo(_vm.processFlow, {
              duration: 300,
              offset: 0,
              easing: 'easeInOutCubic',
            })}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Process Flow")))])],1):_vm._e(),_c('v-list-item',{attrs:{"to":{ name: 'FAQs' },"target":"_blank"}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("FAQs")))])],1),_c('v-list-item',{attrs:{"href":"https://clickdna.rsa.global/","target":"_blank"}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t("Login")))])],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }